<template>
  <div class="p-16">
    <div
      v-for="(filter, idx) in sortedFilters"
      :key="filter.id"
      :class="{
        'pb-16 mb-16 border-b border-neutral-light last:border-b-0 last:pb-0 last:mb-0' : sortedFilters.length > 1 && filter.values.length > 0
      }"
    >
      <div v-if="sortedFilters.length > 1 && filter.values.length > 0" class="type-tag-xs mb-16">{{ filter.displayName }}</div>
      <div
        v-if="filter.values && filter.values.length > 1"
      >
        <div
          v-for="option in filter.values"
          :key="option.id"
          class="type-headline-xs mb-16 flex items-center last:mb-0 cursor-pointer"
          @click="$emit('toggleFilterSelected', option)"
        >
          <div
            class="h-20 w-20 rounded-sm border-neutral-light transition-all mr-12 border flex items-center justify-center type-tag-xs"
            :class="{
              'bg-brand-dark': option.isSelected
            }"
          >
            <Transition name="fade">
              <img
                v-show="option.isSelected"
                src="/zynicons/check.svg"
                class="w-12 h-12"
                alt="Chevron"
              >
            </Transition>
          </div>
          {{ option.displayName }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import type { PropType } from 'vue';
import type { IFilter } from '~/models/api-types';
import { Sizes } from '~/constants/button-layouts';
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    filters: {
      type: Array as PropType<IFilter[]>,
      required: false,
      default: [],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Sizes,
    };
  },
  computed: {
    sortedFilters(): IFilter[] {
      return this.filters.map(filter => {
        const filteredValues = filter.values.filter(value => value.displayName !== null && filter.name != 'brands');
        filteredValues.sort((a, b) => {
          if (a.displayName < b.displayName) return -1;
          if (a.displayName > b.displayName) return 1;
          return 0;
        });
        return {
          ...filter,
          values: filteredValues,
        };
      });
    },
  },
  watch: {
    show(val) {
      if (val) {
        document.body.classList.add('locked');
      } else {
        document.body.classList.remove('locked');
      }
    },
  },
  methods: {},
});
</script>
