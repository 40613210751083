<template>
  <div class="py-40 md:py-56 products-block">
    <ClientOnly>
      <div v-if="isEditMode" class="layout-container mb-24">
        <div
          class="btn btn--dark-theme mr-16 rounded-none px-12"
          data-epi-edit="LayoutType"
        >
          Change layout
        </div>
      </div>
    </ClientOnly>
    <div v-if="data.LinkUrl || data.Heading" class="layout-container">
      <div class="flex justify-between mb-24 md:mr-16 md:px-0 min-h-[30px]">
        <h5
          v-if="data.Heading"
          class="type-headline-base pr-12"
          :style="`color: ${textColor}`"
        >
          {{ data.Heading }}
        </h5>
        <nuxt-link
          v-if="data.LinkUrl && data.LinkText"
          :to="data.LinkUrl"
          class="self-end mb-2"
          :style="`color: ${textColor}`"
        >
          {{ data.LinkText }}
        </nuxt-link>
      </div>
    </div>
    <div
      v-if="data.LayoutType === 'Quickbuy' || data.LayoutType === 'QuickbuySmall'"
      class="layout-container px-0 sm:px-24 md:px-56"
    >
      <div
        class="grid flex-wrap grid-cols-2 gap-y-24 gap-2"
        :class="{
          'sm:gap-y-24 sm:gap-12 md:grid-cols-4 md:gap-24 md:gap-y-48': data.LayoutType === 'Quickbuy',
          'sm:gap-y-24 sm:gap-12 md:grid-cols-5 md:gap-y-32': data.LayoutType === 'QuickbuySmall',
        }"
      >
        <ProductSpot
          v-for="(product, index) in products"
          :key="product.id"
          :product="product"
          :use-schema-props="useSchemaProps"
          :quickbuy-small="data.LayoutType === 'QuickbuySmall' ? true : false"
          :load="index < 7 ? 'eager' : 'lazy'"
        />
      </div>
    </div>
    <div
      v-else-if="data.LayoutType === 'QuickbuySlider' &&
        products &&
        products.length
      "
      class="layout-container px-0 lg:px-56"
      :class="{
        'min-h-[450px]': data.LayoutType === 'QuickbuySlider',
      }"
    >
      <div class="overflow-x-clip">
        <client-only>
          <Swiper
            :modules="[SwiperNavigation, SwiperPagination]"
            :space-between="isMobile ? 8 : 24"
            :slides-per-view="slidesPerView"
            :navigation="true"
            :observer="true"
            :observe-parents="true"
            :auto-height="true"
            :free-mode="true"
            :pagination="{
              type: 'bullets',
            }"
            :breakpoints="{
              '1024': {
                autoHeight: false,
                simulateTouch: false,
                allowTouchMove: false,
              },
            }"
            class="products-slider !overflow-visible"
            :class="{
              'hide-navigation': products.length < 5,
              '!pb-64': products.length > 4,
            }"
          >
            <SwiperSlide
              v-for="(product, index) in products"
              :key="product.id"
              class="swiper-slide flex !h-auto"
            >
              <ProductSpot
                v-if="data.LayoutType === 'QuickbuySlider'"
                :product="product"
                :use-schema-props="useSchemaProps"
                class="product-spot--slider h-full"
                :load="index < 7 ? 'eager' : 'lazy'"
              />
            </SwiperSlide>
          </Swiper>
        </client-only>
      </div>
    </div>
    <div
      v-else-if="data.LayoutType === 'WithoutNameCTASlider' &&
        products &&
        products.length
      "
      class="layout-container px-0 lg:px-56"
    >
      <client-only>
        <Swiper
          :modules="[SwiperNavigation, SwiperPagination]"
          :space-between="isMobile ? 8 : 24"
          :slides-per-view="slidesPerView"
          :navigation="true"
          :observer="true"
          :observe-parents="true"
          :auto-height="true"
          :free-mode="true"
          :pagination="{
            type: 'bullets',
          }"
          :breakpoints="{
            '1024': {
              autoHeight: false,
              simulateTouch: false,
              allowTouchMove: false,
            },
          }"
          class="products-slider px-24"
        >
          <SwiperSlide
            v-for="(product, index) in products"
            :key="product.id"
            class="swiper-slide flex !h-auto"
          >
            <ProductSpotSimple
              :product="product"
              :use-schema-props="useSchemaProps"
              :show-cta="data.LayoutType.includes('WithoutNameCTASlider')"
              :text-color="textColor ? textColor : ''"
              :is-sample="isSample ? isSample : false"
              class="product-spot--slider"
              :load="index < 7 ? 'eager' : 'lazy'"
            />
          </SwiperSlide>
        </Swiper>
      </client-only>
    </div>
  </div>
</template>
<script setup lang="ts">
import ProductSpot from '../ProductSpot.vue';
import ProductSpotSimple from '../ProductSpotSimple.vue';
import type { ProductsBlockFragment } from '#gql';
import { useGlobalContentStore } from '~/store/globalContent';
import {
  type IProductItem,
  PurchaseType,
  type IProductItemsResult
} from '~/models/api-types';

const config = useGlobalContentStore().config;
const { apiGet } = useApiFetch();
const { convertToRelativeUrl } = useStringExtensions();

interface IProps {
  block: ProductsBlockFragment,
  isMobile: boolean,
  isTablet: boolean,
  isEditMode: boolean,
  isSample?: boolean,
  useSchemaProps?: boolean,
  textColor?: string
}

const props = withDefaults(defineProps<IProps>(), {
  useSchemaProps: true,
});

const products: Ref<IProductItem[]> = ref([]);
const loading = ref(false);

const data = computed(() => {
  props.block.ButtonLinks?.forEach((button) => {
    button.Href = convertToRelativeUrl(button?.Href);
  });
  if (props.block.LinkUrl) {
    props.block.LinkUrl = convertToRelativeUrl(props.block.LinkUrl);
  }
  return props.block as ProductsBlockFragment;
});

const productUrls = computed(() => {
  let productString = '';
  if (data.value.Products.length && !data.value.Brands?.length) {
    for (let i = 0; i < data.value.Products.length; i++) {
      if (!data.value?.Products[i]?.Expanded?.UniqueName) {
        continue;
      }
      productString += '&uniqueUrls=' +data.value.Products[i]?.Expanded?.UniqueName;
    }
  }
  return productString;
});

const shouldRenderServerSide = computed(() => {
  return data.value?.LayoutType === 'Autoship' ||
    data.value?.LayoutType === 'Quickbuy' ||
    data.value?.LayoutType === 'WithoutNameCTA';
});

const initialQueryParams = () => {
  let query = '';
  const defaultFilters = props.block.Filters as any;
  Object.keys(defaultFilters).forEach((key: any) => {
    if (defaultFilters[key]?.length) {
      query += `&${key}=${defaultFilters[key]}`;
    }
  });
  return query;
};

const slidesPerView = computed<number>(()=> {
  if (props.isMobile) return 2.1;
  if (props.isTablet) return 3.1;

  return 4;
});

const fetchProducts = async() => {
  const { currentMarket, language } = config;
  loading.value = true;

  let append = '';
  if (props.isSample) {
    append = `&purchaseType=${PurchaseType.Sampling}`;
  } else if (data.value.LayoutType === 'Autoship') {
    append = `&purchaseType=${PurchaseType.Subscription}`;
  }

  if (data.value.Brands?.length) {
    append += `&Brands=${data.value.Brands}`;
  }

  if (!data.value.Brands?.length) {
    append += productUrls.value;
  }

  append += initialQueryParams();

  const query = `productitems?countryCode=${currentMarket?.countryCode}&language=${language}${append}`;
  const res = await apiGet<IProductItemsResult>(query);

  if (res && res.items) products.value = res.items;

  loading.value = false;
};

// If products renders server side
if (shouldRenderServerSide) {
  await fetchProducts();
}

onMounted(async() => {
  if (!shouldRenderServerSide) {
    await fetchProducts();
  }
});

const productsValue = products.value as IProductItem[];
const jsonLd = {
  '@context': 'https://schema.org',
  '@type': 'ItemList',
  itemListElement: productsValue?.map((item, index) => {
    return {
      '@type': 'ListItem',
      position: index,
      url: item.url,
    };
  }),
} as any;

if (props.useSchemaProps) {
  useJsonld(jsonLd);
}
</script>

<style>
.products-slider {
  @apply sm:px-24 md:px-56 lg:px-0;

  .swiper-wrapper {
    align-items: normal !important;
    @apply w-auto !h-auto z-30;
  }

  .swiper-button-next,
  .swiper-button-prev {
    transition: all 0.25s ease-in-out;
    @apply bg-brand-dark h-32 w-32 flex items-center justify-center bottom-0 left-auto top-auto z-20 !rounded-none;
    &.swiper-button-disabled {
      @apply bg-neutral-light pointer-events-none;
    }
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    @apply text-white text-sm font-bold;
  }

  .swiper-button-prev {
    @apply right-[68px] md:right-[98px] lg:right-[44px];
    &::after {
      @apply pr-2;
    }
  }

  .swiper-button-next {
    @apply right-24 md:right-56 lg:right-0;
    &::after {
      @apply pl-2;
    }
  }

  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      @apply opacity-100;
    }
  }

  .swiper-pagination-bullets {
    @apply text-left transform -translate-y-4 pl-24 md:pl-56 lg:pl-0;

    .swiper-pagination-bullet {
      @apply !bg-brand-dark;
    }
  }

  &.hide-navigation .swiper-button-next,
  &.hide-navigation .swiper-button-prev {
    @apply !hidden;
  }
}
</style>
