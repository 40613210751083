<template>
  <div
    ref="spot"
    class="product-spot flex flex-col justify-between relative w-full h-full"
  >
    <div v-if="product" class="flex flex-col h-full">
      <nuxt-link
        :to="productUrl"
        class="p-24 sm:p-48 aspect-square flex items-center justify-center relative max-w-full cursor-pointer"
        :class="{
          'md:aspect-[4/5] md:p-24': quickbuySmall
        }"
        :style="themeBackground"
        @click.native="productClick"
      >
        <div
          class="max-h-240 max-w-320 h-full w-full flex justify-center items-center mx-auto relative"
        >
          <nuxt-img
            :src="gifImage ? gifImage[0]?.url : productImage.image"
            class="product-image h-full w-full max-h-[122px] max-w-[122px] sm:max-w-224 sm:max-h-224 md:max-w-240 md:max-h-240 z-10 object-contain"
            height="360"
            width="360"
            :alt="product.name"
            :preset="gifImage ? '' : 'productImage'"
            loading="lazy"
          />
          <nuxt-img
            v-if="pouchImage && productImage.image && !fullWidthImage && !gifImage"
            preset="standard"
            loading="lazy"
            :src="pouchImage"
            class="pouch-image"
          />
        </div>
        <!-- <FiveDots
          v-if="product.strength && getStrengthNumber(product.strength)"
          :dots="getStrengthNumber(product.strength)"
          :is-product-spot="true"
          class="absolute left-16 bottom-6"
        /> -->
      </nuxt-link>
      <ProductFavouriteIcon
        v-if="config.theme !== 'thunder'"
        :part-no="product.variants[0].partNo"
        :is-product-spot="true"
      />
      <div class="p-12 pb-0 flex flex-col h-full sm:px-0">
        <nuxt-link :to="productUrl" @click.native="productClick">
          <div class="type-headline-sm">{{ product.name }}</div>
        </nuxt-link>
        <nuxt-link :to="productUrl" @click.native="productClick">
          <div v-if="product.description" class="type-sm text-neutral-dark mt-4">
            {{ product.description }}
          </div>
        </nuxt-link>
        <ClientOnly v-if="purchaseEnabled" class="min-h-[50px]">
          <div
            class="quick-buy-container mt-auto sm:flex md:flex-col relative"
            :class="{
              'lg:flex-row': !subscription, 'sm:flex-col lg:flex-col': subscription
            }"
          >
            <SelectorVariants
              v-if="product.variants.length > 0"
              class="quickBuyAmount w-full mr-8 mt-16"
              :class="{
                'opacity-60 pointer-events-none sm:hidden md:flex lg:hidden': allVariantsOutOfStock
              }"
              :product="product"
              :variants="product.variants"
              @change="setVariant"
            />
            <Button
              v-if="purchaseEnabled && inStock"
              :is-block="true"
              :text="actionText"
              :disabled="!inStock"
              :loading="isLoading"
              :hide-text-on-load="true"
              class="btn w-full sm:w-auto flex-shrink-0 mt-16"
              @click="addItemToCart"
            />
            <nuxt-link
              v-if="purchaseEnabled && !inStock"
              :to="productUrl"
              class="btn btn-secondary w-full mt-16"
              @click.native="productClick"
            >
              {{ $lang('productListingResources', 'getNotified') }}
            </nuxt-link>
            <!-- 
            <div class="flex items-center justify-between">
              <div v-if="!inStock" class="self-end flex items-center">
                <div class="h-8 w-8 rounded-full bg-error mr-8 font-medium" />
                {{ $lang('sharedResources', 'outOfStock') }}
              </div>
            </div>
            -->
          </div>
        </ClientOnly>
      </div>
    </div>
    
    <div v-if="product?.flags && product?.flags.length > 0" class="absolute top-16 left-16 z-20 flex items-start flex-wrap">
      <div
        v-for="flag in product.flags.filter(flag => !flag.isPickAndMixFlag)"
        :key="`productSpotFlag-${flag.name}-${product.id}`"
        class="px-6 py-4 mr-12 last:mr-0 mb-6 text-white type-tag-xxs uppercase w-fit-content"
        :style="config.theme === 'thunder' ? '' : themeBackgroundSolid"
      >
        <img
          v-if="flag.code === 'thunder-new'"
          class="h-32"
          src="~/assets/images/thunder-new-flag.png"
        >
        <nuxt-link
          v-else-if="flag.urlSegment"
          :to="flag.urlSegment"
        >
          {{ flag.name }}
        </nuxt-link>
        <span v-else>{{ flag.name }}</span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { useCartStore } from '~/store/cart';
import Button from './globals/Button.vue';
import SelectorVariants from '~/components/product/SelectorVariants.vue';
import FiveDots from '~/components/product-page/FiveDots.vue';
import {
  type IProductItem,
  type IProductVariant,
  PurchaseType
} from '~/models/api-types';
import { usePageContentStore } from '~/store/pageContent';
import * as Sentry from '@sentry/vue';
import { useVoyadoStore } from '~/store/voyado';
import useThemeColors from '~/composables/useThemeColors';
import { useFavouritesStore } from '~/store/favourites';
import ProductFavouriteIcon from '~/components/product/ProductFavouriteIcon.vue';

const globalContentStore = useGlobalContentStore();
const config = useGlobalContentStore().config;
const cartStore = useCartStore();
const { $lang } = useNuxtApp();
const route = useRoute();
const pageStore = usePageContentStore();
const voyado = useVoyadoStore();
const { getStrengthNumber } = useProduct();
const addedFavourites: Ref<string[]> = ref([]);
const favouritesStore = useFavouritesStore();
const { updateFavourites } = storeToRefs(favouritesStore);

addedFavourites.value = favouritesStore.favourites;

interface IProps {
  product: IProductItem,
  isMobile?: boolean,
  useSchemaProps?: boolean,
  subscription?: boolean,
  listName?: string,
  index?: number,
  fullWidthImage?: boolean,
  quickbuySmall?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  useSchemaProps: true,
});

const { themeBackground, themeBackgroundSolid } = useThemeColors(props.product?.flavorColors[0]);

const index = ref(props.index ? props.index : 1);
const selectedVariantId = ref('');
const isLoading = ref(false);
const spot = ref();
const productUrl = ref('');

const selectedVariant = computed(() => {
  return (
    props.product.variants.find(
      (variant) => variant.partNo === selectedVariantId.value
    ) || props.product.variants[0] || null
  );
});

const allVariantsOutOfStock = computed<boolean>(()=> {
  let hasVariantInStock = false;
  props.product.variants?.forEach((variant: IProductVariant) => {
    if (variant.inStock) {
      hasVariantInStock = true;
    }
  });
  return !hasVariantInStock;
});

const pouchImage = computed(() => {
  return props.product.pouchImageUrl;
});

const gifImage = computed(() => {
  if (!config.currentMarket?.showGifImagesOnProductListingPage) return false;

  const gifImage = props.product.additionalImages?.filter((image) => {
    return image?.code == 'gif';
  });

  if (gifImage.length) return gifImage;

  return false;
});

const productImage = computed((): { image: string; shouldShowShadow: boolean } => {
  const variantIndex = props.product.variants
    .map((variant) => variant.partNo)
    .indexOf(selectedVariantId.value);

  return selectedVariant.value?.imageUrl
    ? {
      image: `${selectedVariant.value?.imageUrl}?w=480&h=480`,
      shouldShowShadow: variantIndex <= 0 && props.product.showCanShadow,
    }
    : {
      image: `${props.product.imageUrl}?w=480&h=480`,
      shouldShowShadow: true,
    };
});

const inStock = computed(() => {
  return !!selectedVariant.value?.inStock;
});

const actionText = computed(() => {
  return inStock.value
    ? $lang(
      'productListingResources',
      props.subscription ? 'subscribeButton' : 'purchase'
    )
    : $lang('productListingResources', 'outOfStock');
});

const isBundleProduct = computed(() => {
  return (
    selectedVariant.value?.productType === 'ManagedStructure' ||
    selectedVariant.value?.productType === 'ManagedErpPPackage' ||
    selectedVariant.value?.productType === 'ManagedErpPackageWithCalc'
  );
});

const purchaseEnabled = computed(() => {
  return !!config.currentMarket?.enablePurchases;
});

const showPricePerCan = computed(() => {
  if (!isBundleProduct.value && props.product.variants.length < 2) {
    return false;
  } else if (!isBundleProduct.value) {
    return true;
  } else if (
    selectedVariant.value &&
        selectedVariant.value.pricePerCan !== selectedVariant.value.price
  ) {
    return true;
  }
  return false;
});

onBeforeMount(() => {
  productUrl.value = props.product.url!;
});

onMounted (() => {
  nextTick(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            window.dataLayer?.push({
              event: 'productImpressions',
              ecommerce: {
                currencyCode: selectedVariant.value?.currencyCode,
                impressions: [
                  {
                    list: props.listName ?? route.path,
                    ...selectedVariant.value?.gtmProperties,
                    variant: '1-pack',
                  },
                ],
              },
            });
            if (selectedVariant.value) {
              voyado.sendProductView(selectedVariant.value);
            }
            if (spot.value) {
              observer.unobserve(spot.value as Element);
            }
          }
        });
      },
      { rootMargin: '0px', threshold: 0.5, root: null }
    );
    if (spot.value) {
      observer.observe(spot.value as Element);
    }
  });
});

const productClick = () => {
  window.dataLayer?.push({
    event: 'productClick',
    ecommerce: {
      currencyCode: selectedVariant.value?.currencyCode,
      click: {
        actionField: {
          list: props.listName ?? route.path,
        },
        products: [
          {
            position: props.index,
            ...selectedVariant.value?.gtmProperties,
            variant: '1-pack',
          },
        ],
      },
    },
  });
};

const setVariant = (id: string) => {
  selectedVariantId.value = id;
  window.sessionStorage.removeItem(selectedVariant.value?.gtmProperties.id + '-packageSize');
  window.sessionStorage.setItem(selectedVariant.value?.gtmProperties.id + '-packageSize', selectedVariant.value?.packageSize);
};

const addItemToCart = async() => {
  if (selectedVariant.value) {
    try {
      isLoading.value = true;
      await cartStore.addItemToCart({
        item: selectedVariant.value,
        purchaseType: props.subscription
          ? PurchaseType.Subscription
          : PurchaseType.Standard,
        hidePopup: false,
      });
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      isLoading.value = false;
    }
  }
};

const isProductPage = computed(() => {
  return pageStore.pageType === 'ProductPage';
});

const product = props.product as IProductItem | null;
const selectedVariantValue = selectedVariant.value as IProductVariant | null;
const host = globalContentStore.host;

const jsonLd = {
  '@context': 'https://schema.org',
  '@type': 'Product',
  gtin: product?.ean,
  name: product?.name,
  description: product?.description,
  sku: selectedVariantValue?.gtmProperties.id,
  image: product?.imageUrl,
  brand: {
    '@type': 'Brand',
    name: selectedVariantValue?.gtmProperties.brand,
  },
  url: host + (product?.url ?? ''),
  offers: {
    '@type': 'Offer',
    priceCurrency: selectedVariantValue?.currencyCode,
    price: selectedVariantValue?.gtmProperties?.price
      ?.toString()
      ?.replace(',', '.'),
    availability: selectedVariantValue?.inStock
      ? 'https://schema.org/InStock'
      : 'https://schema.org/OutOfStock',
  },
} as any;

if (props.useSchemaProps && !isProductPage.value) {
  useJsonld(jsonLd);
}
</script>
<style scoped lang="postcss">
.pouch-image {
  bottom: -41px;
  @apply max-h-112 absolute right-0 z-20;
}

.can-image {
  position: absolute;
  bottom: -20px;
  right: 0;
  left: 0;
  margin: 0 auto;
  height: 100%;
  width: 100%;
}

.tooltip-top::after {
  content: "";
  position: absolute;
  top: 100%;
  right: 24px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}
</style>
