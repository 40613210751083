<template>
  <div>
    <div class="type-base mb-16">
      {{ $lang('loginResources', 'forgotPasswordModalHeading') }}
    </div>
    <div v-if="!requestSent">
      <div class="border-neutral-light">
        <div class="form-row border">
          <div class="flex-1">
            <TextInput
              :id="'checkoutSignInEmail'"
              :placeholder="$lang('checkoutResources', 'formEmail')"
              :type="'email'"
              :form-input="true"
              :error="emailError"
              @submit-input="emailInput"
            />
          </div>
        </div>
      </div>
      <div class="mt-24">
        <Button
          :is-block="true"
          :text="$lang('loginResources', 'forgotPasswordButton')"
          :size="Sizes.lg"
          :theme="Themes.dark"
          :loading="forgotPasswordLoading"
          @click="onForgotPassword"
        />
      </div>
    </div>
    <div v-else class="type-base">
      {{ $lang('loginResources', 'forgotPasswordSuccessful') }}
    </div>
    <div
      class="mt-24 mb-8 type-base text-center underline cursor-pointer select-none"
      @click="$emit('switch-form')"
    >
      {{ $lang('checkoutResources', 'signInButton') }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { Sizes, Themes } from '../constants/button-layouts';
import Button from './globals/Button.vue';
import TextInput from './form-elements/TextInput.vue';
import { useGlobalContentStore } from '~/store/globalContent';
import * as Sentry from '@sentry/vue';

const config = useGlobalContentStore().config;
const email = ref('');
const emailError = ref('');
const forgotPasswordLoading = ref(false);
const requestSent = ref(false);

const { apiPost } = useApiFetch();

const { $lang } = useNuxtApp();

const emailInput = (input: string) => {
  email.value = input;

  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.trim()) && input) {
    emailError.value = '';
  } else if (!input) {
    emailError.value = '';
  } else {
    emailError.value = $lang(
      'validationErrorResources',
      'invalidEmailShort'
    );
  }
};

const onForgotPassword = async() => {
  if (email.value == '') {
    emailError.value = $lang(
      'validationErrorResources',
      'invalidEmailShort'
    );
    return;
  }

  forgotPasswordLoading.value = true;

  await apiPost(
    `user/account/passwordreset?countryCode=${config.currentMarket?.countryCode}&language=${config.language}`,
    {
      email: email.value,
    }
  );
  requestSent.value = true;

  forgotPasswordLoading.value = false;
};
</script>
<style scoped>
.form-row {
  @apply flex border-t border-l border-r;
}
</style>
