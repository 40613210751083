export default function useThemeColors(color: string | null | undefined) {

  const getBrightness = (color: string) => {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substring(0, 0 + 2), 16);
    const c_g = parseInt(hex.substring(2, 2 + 2), 16);
    const c_b = parseInt(hex.substring(4, 4 + 2), 16);
    return ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
  };

  const isLight = (color: string) => {
    const brightness= getBrightness(color);
    return brightness > 155;
  };

  const themeColorSwiper = computed(()=> {
    if (color) {
      return { '--swiperTheme': color };
    }
    return null;
  });

  const themeBackground = computed(()=> {
    if (color) {
      // theme color with 20% opacity
      return {
        background: `${color}33`,
      };
    }
    return {
      background: 'var(--color-neutral-lighter)',
    };
  });

  const themeBackgroundAndText = computed(()=> {
    if (color) {
      return {
        background: `${color}33`,
        color: color,
      };
    }
    return {
      background: 'var(--color-neutral-lighter)',
    };
  });

  const themeBackgroundSolid = computed(()=> {
    if (color) {
      return {
        background: `${color}`,
      };
    }
    return {
      background: 'var(--color-brand-primary)',
    };
  });

  const themePrimaryButton = computed(()=> {
    if (color) {
      return {
        'background-color': `${color}`,
        '--color-brand-dark': `${color}`,
        '--color-brand-primary': `color-mix(in srgb, ${color} 75%, ${isLight(color) ? 'var(--color-neutral-darkest)':'var(--color-neutral-lightest)'})`,
        color: isLight(color) ? 'var(--color-neutral-darkest)':'var(--color-neutral-lightest)',
      };
    }
    return null;
  });

  const themeBasicButton = computed(()=> {
    if (color) {
      return {
        color,
        '--color-neutral-darkest': color,
      };
    }
    return null;
  });

  return {
    themeBackground,
    themeColorSwiper,
    themeBackgroundSolid,
    isLight,
    themePrimaryButton,
    themeBackgroundAndText,
    themeBasicButton,
  };
}
