<template>
  <div>
    <Modal
      :newsletter-modal="true"
      :with-image="hasImage"
      :background="background"
      class="!z-modal flex newsletter-modal"
      @close-modal="$emit('close-newsletter-modal')"
    >
      <div
        v-if="hasImage && !isMobile"
        class="side-image h-full md:w-1/2"
        :style="style"
      />
      <br>
      <div
        :class="{
          'md:w-1/2 pt-56': hasImage && !isMobile,
        }"
        :style="background"
      >
        <div
          v-if="hasImage && isMobile"
          class="side-image side-image-mobile w-full"
          :style="style"
        />
        <div
          class="p-24 pt-0"
          :class="{
            'flex-auto pt-56': !hasImage,
            'pt-40': hasImage && isMobile,
          }"
        >
          <div v-if="newsletterIsSubscribed">
            <WysiwygWrapper
              class="subscribed-content"
              :style-config="{ 'mb-12': true }"
              :html="newsletterModalConfig.afterSignUpMessage"
            />
          </div>
          <div v-else>
            <div class="mb-12 type-headline-lg">
              {{ newsletterModalConfig.heading }}
            </div>
            <div class="flex mt-16 mb-40">
              <WysiwygWrapper
                :style-config="{
                  'wysiwyg': true,
                }"
                :html="newsletterModalConfig.mainBody"
              />
            </div>
            <TextInput
              :id="'newsletterModalField'"
              :placeholder="$lang('footerResources', 'emailAddress')"
              :error="emailError"
              :type="'email'"
              :newsletter-modal="true"
              @submit-input="onEmailChange"
              @enter-submit="onSubmitNewsLetter"
            />
            <div class="flex mt-16">
              <WysiwygWrapper
                :style-config="{
                  'mt-8 type-xs': true,
                }"
                :html="newsletterModalConfig.termsAndConditions"
              />
            </div>
            <Button
              class="mt-24 w-full text-base"
              :text="$lang('sharedResources', 'subscribe')"
              :size="Sizes.lg"
              :theme="Themes.dark"
              :loading="newsletterSubmitLoading"
              @click="onSubmitNewsLetter"
            />
            <div v-if="newsletterError" class="mt-16 type-xs text-critical-primary">
              {{ newsletterError }}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script setup lang="ts">
import { Sizes, Themes } from '../../constants/button-layouts';
import { type IMarket } from '~/models/api-types';
import Button from '../globals/Button.vue';
import TextInput from '../form-elements/TextInput.vue';
import Modal from '~/components/modals/Modal.vue';
import { useGlobalContentStore } from '~/store/globalContent';
import { useVoyadoStore } from '~/store/voyado';
import useThemeColors from '~/composables/useThemeColors';
const { $event } = useNuxtApp();

const config = useGlobalContentStore().config;
const voyadoStore = useVoyadoStore();

const { apiPost, lastError } = useApiFetch();
const { $lang } = useNuxtApp();

defineProps<{
  isMobile?: boolean,
}>();

const emailError = ref('');
const newsletterEmail = ref('');
const newsletterError = ref('');
const newsletterIsSubscribed = ref(false);
const newsletterSubmitLoading = ref(false);

const newsletterModalConfig = computed (() => {
  const { newsletterModal } = config;
  return newsletterModal;
});

const hasImage = computed (() => {
  if (newsletterModalConfig.value?.backgroundImage) {
    return true;
  }
  return false;
});

const style = computed (() => {
  if (newsletterModalConfig.value?.backgroundImage) {
    return `background-image: url(${newsletterModalConfig.value?.backgroundImage})`;
  }
  return `background-color: ${newsletterModalConfig.value?.backgroundColor}`;
});

const background = computed (() => {
  return `background-color: ${newsletterModalConfig.value?.backgroundColor}`;
});

const route = useRoute();
watch(
  () => route.fullPath,
  () => {
    if (newsletterIsSubscribed.value) {
      emit('close-newsletter-modal');
    }
  }
);

const emit = defineEmits<{
  (event: 'close-newsletter-modal'): void,
}>();

const onEmailChange = (input: string) => {
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.trim()) && input) {
    emailError.value = $lang('footerResources', 'invalidEmail');
  } else {
    emailError.value = '';
  }
  newsletterEmail.value = input;
};

const onSubmitNewsLetter = async() => {
  if (!!newsletterEmail.value && !emailError.value) {
    newsletterSubmitLoading.value = true;
    const { currentMarket, language } = config;
    const { countryCode, useVoyado } = currentMarket as IMarket;

    if (useVoyado) {
      const voyadoRes = await apiPost<string>(
        `voyado/newsletter?countryCode=${countryCode}&language=${language}`,
        {
          email: newsletterEmail.value,
          countryCode,
          language,
        }
      );

      if (voyadoRes) {
        voyadoStore.setContactId(voyadoRes);
        newsletterError.value = '';
        newsletterIsSubscribed.value = true;
        window.dataLayer?.push({
          event: 'newsletter_signup',
        });
      } else if (lastError.value) {
        const message = lastError.value || '';
        $event('trigger-error-bar', {
          message,
        });
      }
    }

    newsletterSubmitLoading.value = false;
  } else {
    newsletterError.value = $lang('footerResources', 'incompleteData');
  }
};
</script>
<style>
.newsletter-modal {
  .close-modal-button {
    @apply rounded-full bg-white h-32 w-32 md:rounded-none md:bg-transparent md:h-auto md:w-auto;
  }

  .subscribed-content {
    .btn--md {
      padding-top: 17px;
      padding-bottom: 17px;
    }
  }
}
</style>
<style scoped>
.form-row {
  @apply flex border-t border-l border-r;
}

.flex .meta-info-text p a {
  text-decoration: underline;
}

.side-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.side-image-mobile {
  min-height: 169px;
  display: block;
}
</style>
