<template>
  <div class="mb-40">
    <div v-if="!hideTitle" class="type-base mb-16">
      {{ $lang('checkoutResources', 'signInModalHeading') }}
    </div>
    <div class="border-neutral-light">
      <div class="form-row">
        <div class="flex-1">
          <TextInput
            :id="'checkoutSignInEmail'"
            :placeholder="$lang('checkoutResources', 'formEmail')"
            :type="'email'"
            :form-input="true"
            :error="emailError"
            :autocomplete="'email'"
            @submit-input="emailInput"
          />
        </div>
      </div>
      <div class="form-row last:border-b">
        <div class="flex-1">
          <TextInput
            :id="'checkoutSignInPassword'"
            :placeholder="$lang('checkoutResources', 'formPassword')"
            :type="'password'"
            :autocomplete="'password'"
            :form-input="true"
            :is-password-input="true"
            @submit-input="passwordInput"
            @enter-submit="onLogIn"
          />
        </div>
      </div>
      <div class="form-row last:border-b">
        <div class="flex-1 items-center p-16 flex">
          <Checkbox
            :checked="shouldSaveInfo"
            :text="$lang('checkoutResources', 'signInSaveInfo')"
            :thin="true"
            @on-check="toggleSaveInfo"
          />
          <div
            class="select-none cursor-pointer underline ml-auto type-xs"
            @click="$emit('switch-form')"
          >
            {{ $lang('loginResources', 'forgotPasswordSwitch') }}
          </div>
        </div>
      </div>
    </div>
    <AlertBar
      v-if="responseError"
      class="mt-24"
      :alert-type="'error'"
      :text="responseError"
    />
    <div class="mt-24">
      <Button
        :is-block="true"
        :text="$lang('checkoutResources', 'signInButton')"
        :size="buttonMedium ? Sizes.md : Sizes.lg"
        :theme="Themes.dark"
        :loading="logInLoading"
        @click="onLogIn"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { useUserStore } from '~/store/user';
import { Sizes, Themes } from '../constants/button-layouts';
import Button from '~/components/globals/Button.vue';
import Checkbox from '~/components/form-elements/Checkbox.vue';
import TextInput from '~/components/form-elements/TextInput.vue';
import { storeToRefs } from 'pinia';

const { $event } = useNuxtApp();
const globalContentStore = useGlobalContentStore();
const userStore = useUserStore();
const { $lang } = useNuxtApp();
const { lastError } = useApiFetch();

const props = defineProps<{
  navigateToUrl?: string,
  buttonMedium?: boolean,
  hideTitle?: boolean,
}>();

const route = useRoute();

const emit = defineEmits<{
  (event: 'logged-in'): void,
}>();

const email = ref('');
const password = ref('');
const emailError = ref('');
const responseError = ref('');
const shouldSaveInfo = ref(false);
const logInLoading = ref(false);

const emailInput = (input: string) => {
  email.value = input;
  responseError.value = '';

  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.trim()) && input) {
    emailError.value = '';
  } else if (!input) {
    emailError.value = '';
  } else {
    emailError.value = $lang(
      'validationErrorResources',
      'invalidEmailShort'
    );
  }
};

const passwordInput = (input: string) => {
  password.value = input;
  responseError.value = '';
};

const toggleSaveInfo = (checked: boolean) => {
  shouldSaveInfo.value = checked;
};

const onLogIn = async() => {
  logInLoading.value = true;

  const res = await userStore.logIn({
    email: email.value,
    password: password.value,
    rememberMe: shouldSaveInfo.value,
  });

  const errorMessage = lastError.value || res?.errorMessage;

  if (errorMessage) {
    responseError.value = errorMessage;
  } else if (props.navigateToUrl) {
    window.location.href = props.navigateToUrl;
    window.dataLayer?.push({
      event: 'Login',
      eventCategory: 'user',
      eventAction: 'login',
    });
  } else {
    window.dataLayer?.push({
      event: 'Login',
      eventCategory: 'user',
      eventAction: 'login',
    });

    emit('logged-in');
  }
  logInLoading.value = false;
};

const runTimeConfig = useRuntimeConfig();
const { requestVerificationToken } = storeToRefs(globalContentStore);
const fetchAntiForgeryToken = async() => {
  const result = await $fetch(`${runTimeConfig.public.apiUrl}website/xsrf/token`);
  requestVerificationToken.value = result;
};

</script>
<style scoped>
.form-row {
  @apply flex border-t border-l border-r;
}
</style>
