
import * as cloudflareRuntime$S4pr6fFOjF from '/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'
import * as customProviderRuntime$jqiq7OyOBM from '/opt/atlassian/pipelines/agent/build/nuxt-app/providers/norce.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 1024,
    "lg": 1190,
    "xl": 1372,
    "xxl": 1600,
    "2xl": 1536,
    "mob375": 375,
    "mob390": 390,
    "mob414": 414
  },
  "presets": {
    "standard": {
      "modifiers": {
        "format": "webp",
        "quality": 75,
        "densities": "1x 2x"
      }
    },
    "productImage": {
      "modifiers": {
        "format": "webp",
        "quality": 95,
        "densities": "1x 2x"
      }
    },
    "blockImages": {
      "modifiers": {
        "format": "webp",
        "quality": 80,
        "densities": "1x 2x"
      }
    },
    "hero": {
      "modifiers": {
        "format": "webp",
        "quality": 90,
        "densities": "1x 2x"
      }
    }
  },
  "provider": "cloudflare",
  "domains": [
    "niqo.localhost:8090",
    "niqo.localhost",
    "thunder.localhost:8091",
    "int.niqo.com",
    "int.thundernp.com",
    "preprod.niqo.com",
    "preprod.thundernp.com",
    "www.niqo.com",
    "www.thundernp.com",
    "swedishmatch.cdn.storm.io",
    "swedishmatch.cdn-stage.storm.io",
    "localhost:8092"
  ],
  "alias": {
    "/intv2": "int.niqo.com"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudflare']: { provider: cloudflareRuntime$S4pr6fFOjF, defaults: {} },
  ['norce']: { provider: customProviderRuntime$jqiq7OyOBM, defaults: {} }
}
        