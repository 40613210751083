import { defineStore } from 'pinia';

export const useUiStore = defineStore('uiStore', {
  state: () => ({
    isMobile: true,
    isTablet: false,
    forceHideAgeGate: false,
    showLogin: false,
    showCartMenu: false,
    showSearch: false,
    showDesktopSearch: false,
    preventNavigation: false,
  }),
  actions: {
    setIsMobile(payload: boolean) {
      this.isMobile = payload;
    },
    setIsTablet(payload: boolean) {
      this.isTablet = payload;
    },
    setForceHideAgeGate(payload: boolean) {
      this.forceHideAgeGate = payload;
    },
    setShowLogin(payload: boolean) {
      this.showLogin = payload;

      if (payload === false) {
        document.body.classList.remove('locked');
      }
    },
    setShowCartMenu(payload: boolean) {
      this.showCartMenu = payload;
    },
    closeAllModals() {
      this.showSearch = false;
      this.showDesktopSearch = false;
      this.showCartMenu = false;
      this.showLogin = false;
    },
    setShowSearch(payload: boolean) {
      this.showSearch = payload;

      if (payload) {
        document.body.classList.add('locked');
      } else {
        document.body.classList.remove('locked');
      }
    },
    setShowDesktopSearch(payload: boolean) {
      this.showDesktopSearch = payload;

      if (payload) {
        document.body.classList.add('locked');
      } else {
        document.body.classList.remove('locked');
      }
    },
    // State to prevent navigation to my pages after login or create account
    setPreventNavigation(payload: boolean) {
      this.preventNavigation = payload;
    },
  },
  getters: {
    showModalOverlay(state) {
      return state.showCartMenu || state.showSearch;
    },
  },
});
