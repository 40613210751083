import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/nuxt-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iGLjBbor9q from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_Jozdw60ZsE from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_jBToDIZpFa from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import epiEdit_e8LmJyruGI from "/opt/atlassian/pipelines/agent/build/nuxt-app/plugins/epiEdit.ts";
import fontawesome_klhsrycjcK from "/opt/atlassian/pipelines/agent/build/nuxt-app/plugins/fontawesome.js";
import init_grdyAU8lhf from "/opt/atlassian/pipelines/agent/build/nuxt-app/plugins/init.ts";
import lang_Yip5L8Jphl from "/opt/atlassian/pipelines/agent/build/nuxt-app/plugins/lang.ts";
import mitt_client_JdecFmZkyR from "/opt/atlassian/pipelines/agent/build/nuxt-app/plugins/mitt.client.js";
import onError_GBuFIufoYZ from "/opt/atlassian/pipelines/agent/build/nuxt-app/plugins/onError.ts";
import sentry_client_shVUlIjFLk from "/opt/atlassian/pipelines/agent/build/nuxt-app/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_iGLjBbor9q,
  plugin_Jozdw60ZsE,
  plugin_jBToDIZpFa,
  epiEdit_e8LmJyruGI,
  fontawesome_klhsrycjcK,
  init_grdyAU8lhf,
  lang_Yip5L8Jphl,
  mitt_client_JdecFmZkyR,
  onError_GBuFIufoYZ,
  sentry_client_shVUlIjFLk
]