<template>
  <div
    class="z-errorBar right-12 left-12 bottom-12 sm:right-16 sm:left-16 bg-attention-light type-xs p-16 flex justify-center items-center pr-40 fixed sm:bottom-16"
  >
    {{ text }}
    <div
      class="absolute right-12 sm:right-16 p-8 cursor-pointer select-none top-10 md:top-8"
      @click="$emit('closeSnackBar')"
    >
      <fa :icon="['fal', 'times']" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    staticErrorBar: {
      type: Boolean,
      required: false,
      default: false,
    },
    usingUsps: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
</script>
