<template>
  <div>
    <transition name="notice-animation">
      <div
        v-if="favouriteNoticeOpen"
        class="fixed max-w-360 w-full bg-white z-header flex flex-1 flex-col border border-grey500 bottom-24 md:bottom-48 right-16 md:right-48 shadow-md p-16 sm:p-24"
        @mouseenter="pauseFavouriteNoticeTimer"
        @mouseleave="startFavouriteNoticeTimer"
      >
        <div class="flex mb-12 items-center">
          <div class="text-sm font-medium">
            Tillagd i snabbval
          </div>
        </div>
        <a class="btn btn-primary w-full" :href="favouritesPage">
          Gå till mina snabbval
        </a>
        <button class="btn rounded-full border-neutral-light absolute top-16 right-16" @click="favouriteNoticeOpen = false">
          <img
            src="~assets/icons/close.png"
            class="cursor-pointer select-none"
            height="16"
            width="16"
          >
        </button>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeUnmount } from 'vue';
import { useFavouritesStore } from '~/store/favourites';
import { useGlobalContentStore } from '~/store/globalContent';
const globalContentStore = useGlobalContentStore();
const favouriteStore = useFavouritesStore();
const favouritesPage = globalContentStore.config.currentMarket?.favouritesPageUrl;

const unsubscribe = favouriteStore.$onAction(
  ({ after, args, name }) => {
    after(() => {
      if (name == 'addToFavourites') {
        openFavouriteNotice();
      }
    });
  }
);
type timer = ReturnType<typeof setTimeout>;
let favouriteNoticeTimer: timer;

const favouriteNoticeOpen = ref(false);

const openFavouriteNotice = () => {
  favouriteNoticeOpen.value = true;
  startFavouriteNoticeTimer();
};

const startFavouriteNoticeTimer = () => {
  favouriteNoticeTimer = setTimeout(() => favouriteNoticeOpen.value = false, 2000);
};

const pauseFavouriteNoticeTimer = () => {
  clearTimeout(favouriteNoticeTimer);
};

onBeforeUnmount(() => {
  unsubscribe();
});
</script>

<style>
.notice-animation-enter-active,
.notice-animation-leave-active {
  transition: all 0.3s ease-out;
}

.notice-animation-enter-from,
.notice-animation-leave-to {
  opacity: 0;
  transform: translateY(15%);
}
</style>
