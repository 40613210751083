<template>
  <div class="mb-24 last:mb-0">
    <div
      class="flex justify-between md:select-text select-none md:cursor-auto cursor-pointer base-medium-text"
      :class="{
        'text-grey800': colorScheme === 'light',
        'text-grey200': colorScheme === 'dark',
      }"
      @click="toggleOpen"
    >
      {{ heading }}
      <div v-if="isMobile">
        <ClientOnly>
          <fa v-if="isOpen" :icon="['fal', 'minus']" />
          <fa v-else :icon="['fal', 'plus']" />
        </ClientOnly>
      </div>
    </div>
    <div v-if="isOpen || !isMobile">
      <div
        v-for="item in items"
        :key="'subMenuFoldOutItem' + item.text"
        class="mt-12 cursor-pointer select-none"
        @click="$emit('close-overlay')"
      >
        <template v-if="item.href.startsWith('http')">
          <a
            :href="item.href"
            :target="item.target"
            :title="item.title"
            class="small-base-text"
            :class="{
              'text-grey600': colorScheme === 'light',
              'text-grey400': colorScheme === 'dark',
            }"
          >
            {{ item.text }}
          </a>
        </template>
        <template v-else>
          <nuxt-link
            :to="item.href"
            :target="item.target"
            :title="item.title"
            class="small-base-text"
            :class="{
              'text-grey600': colorScheme === 'light',
              'text-grey400': colorScheme === 'dark',
            }"
          >
            {{ item.text }}
          </nuxt-link>
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { type PropType } from 'vue';
import type { ILinkItem } from '~/models/api-types';
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    heading: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<ILinkItem[]>,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    toggledRow: {
      type: String,
      required: true,
    },
    colorScheme: {
      type: String,
      required: false,
      default: 'light',
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isOpen(): boolean {
      return this.toggledRow === this.id;
    },
  },
  methods: {
    toggleOpen() {
      if (this.isMobile) {
        this.$emit('toggleMenuRow', this.id);
      }
    },
  },
});
</script>
