import { defineStore } from 'pinia';
import type { ILayoutModel } from '~/models/api-types';
import { IContent } from '~/models/content';

type State = {
  basketOffset: number;
  subscriptionOffset: number;
  page: IContent | null;
  siteSettings: ILayoutModel,
} & ILayoutModel;

export const useGlobalContentStore = defineStore('globalContentStore', {
  state: (): State => ({
    host: '' as string,
    siteSettings: {} as ILayoutModel,
    language: '',
    markets: [],
    basketOffset: 0,
    subscriptionOffset: 0,
    page: null,
    requestVerificationToken: undefined,
    epiClientResourcePath: '',
    showEditButton: false,
    notFoundPageContentId: 0,
    ageGateImageUrl: '',
    ageGateImageUrlMobile: '',
    ageGateUnder18LinkUrl: '',
    isImpersonating: false,
    isLoggedInAsAdmin: false,
    isLoggedInAsImpersonator: false,
    isLoggedIn: false,
    siteMessages: [],
    checkoutSiteMessages: [],
    environmentName: '',
    theme: '',
    userId: '',
    voyadoScriptSrc: '',
  }),
  actions: {
    async loadFromCms(urlValue: string) {
      // this is where we load stuff like navigation, footer, etc and place in the store
      const runtimeConfig = useRuntimeConfig();
      const host = useRequestHeaders()['x-forwarded-host'];
      const cookie = useRequestHeaders()['cookie'];
      const ipCountry = useRequestHeaders()['cf-ipcountry'];
      this.host = `https://${host}`;
      let url = '';

      if (urlValue) {
        url = urlValue;
      } else {
        url = useRequestEvent().node.req.url || '';
      }
      let opts = {};
      if (host) {
        opts = {
          headers: { 'X-Epi-Host' : host, cookie },
        };
      }
      if (ipCountry) {
        opts = {
          headers: { 'X-Epi-Host' : host, cookie, 'CF-IPCountry': ipCountry },
        };
      }
      const result = await $fetch<ILayoutModel>(`${process.client ? runtimeConfig.public.apiUrl : runtimeConfig.apiUrl}website?pageUrl=${url}`, opts);
      this.siteSettings = result;
      this.isLoggedIn = result.isLoggedIn;
    },
    setOffsets({ basketOffset, subscriptionOffset }: { basketOffset:number, subscriptionOffset: number }): void {
      this.basketOffset = basketOffset;
      this.subscriptionOffset = subscriptionOffset;
    },
  },
  getters: {
    config: (state) => {
      return state.siteSettings;
    },
    header(state) {
      return state.siteSettings.header;
    },
    footer(state) {
      return state.siteSettings.footer;
    },
    currentMarket: (state) => {
      return state.siteSettings.currentMarket;
    },
    purchaseEnabledOnMarket: (state) => {
      return state.siteSettings.currentMarket?.enablePurchases;
    },
    getTheAntiForgeryToken(state) {
      return state.requestVerificationToken;
    },
    newsletterModal: (state) => {
      return state.newsletterModal;
    },
    getAcceptLanguage(state) {
      return state.siteSettings.language;
    },
    isWrongCountry: (state) => {
      return state.siteSettings.isWrongCountry;
    },
    ipCountry: (state) => {
      return state.siteSettings.ipCountry;
    },
    pageType: (state) => {
      return state.siteSettings.contentType;
    },
    useTakeOverHeader: (state) => {
      return state.siteSettings.useTakeOverHeader;
    },
    useCampaignHeader: (state) => {
      return state.siteSettings.useCampaignHeader;
    },
    showStickyWarning: (state): boolean => {
      return state.siteSettings.currentMarket.nicotineWarning && state.siteSettings.currentMarket.nicotineWarning.trim() !== '';
    },
  },
});
