<template>
  <div>
    <label
      class="checkbox-container block relative cursor-pointer select-none type-xs h-full"
      :class="{
        'mb-12': !thin,
        ...containerStyles,
      }"
    >
      <div class="relative pl-32 type-headline-xs">
        {{ text }}
        <input
          class="absolute opacity-0 cursor-pointer h-0 w-0"
          type="checkbox"
          :checked="checked"
          @change="onCheck"
        >
        <span
          class="checkmark absolute top-0 left-0 bg-transparent rounded-sm border"
          :class="{
            'border-grey200': lightTheme,
            'border-grey600': !lightTheme,
          }"
        />
      </div>
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
    checked: {
      type: Boolean,
      required: true,
    },
    lightTheme: {
      type: Boolean,
      required: false,
      default: false,
    },
    thin: {
      type: Boolean,
      required: false,
      default: false,
    },
    containerStyles: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  methods: {
    onCheck(e: any) {
      this.$emit('onCheck', e.target.checked);
    },
  },
});
</script>

<style>
.checkmark {
  height: 20px;
  width: 20px;
  transition: all 0.1s ease-out;
}

.checkbox-container:hover input ~ .checkmark {
  @apply bg-neutral-lighter;
}

.checkbox-container input:checked ~ .checkmark {
  border: none;
  @apply bg-brand-primary;
}

.checkmark::after {
  content: '';
  position: absolute;
  opacity: 0;
}

.checkbox-container input:checked ~ .checkmark::after {
  opacity: 100;
}

.checkbox-container .checkmark::after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
