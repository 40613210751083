<template>
  <div class="article-page p0 pb-40 md:pb-56">
    <div class="layout-container md:w-936">
      <div class="empty:hidden aspect-16/9">
        <nuxt-img
          v-if="isMobile && article.Image"
          preset="standard"
          loading="lazy"
          :src="article.Image.Url"
          class="w-full aspect-16/9 object-cover"
          :alt="article.AltText"
        />
        <nuxt-img
          v-else-if="article.Image"
          preset="standard"
          loading="lazy"
          :src="article.Image.Url"
          class="w-full aspect-16/9 object-cover"
          :alt="article.AltText"
          sizes="md:936px"
        />
      </div>
      <div class="mx-auto pt-24 md:pt-48 pb-40 md:w-696">
        <div v-if="page.Category?.length" class="mb-8">
          <div
            v-for="(category, cIndex) in page.Category"
            :key="cIndex"
            class="inline-flex mr-8 mb-8 h-20 px-6 items-center
                  bg-brand-dark text-neutral-lightest
                  type-tag-xxs uppercase"
          >
            {{ category?.Name }}
          </div>
        </div>
        <h1 class="mb-12 type-headline-2xl text-brand-dark">{{ article.Heading }}</h1>
        <div
          v-if="article.Released"
          class="type-xs text-neutral-dark mb-24"
        >
          {{ dayjs(article.Released).locale(config.language).format('D MMMM YYYY') }}
        </div>

        <p class="type-md mb-24">{{ article.Preamble }}</p>
        <WysiwygWrapper
          class="wysiwyg"
          :html="article.MainBody"
        />
      </div>
    </div>
    <div>
      <BlocksContentArea
        v-for="(block, index) in blocks"
        :key="'BrandPageBlock-' + block?.ContentLink?.Expanded?.__typename + '-' + index"
        :block="block?.ContentLink?.Expanded"
        :data-epi-edit="'ContentArea'"
      />
      <ArticleTeaserBlock
        v-if="
          article.ArticleTeasers &&
            article.ArticleTeasers.Articles &&
            article.ArticleTeasers.Articles.length
        "
        :block="article.ArticleTeasers"
        :is-mobile="isMobile"
        :is-edit-mode="isEditMode"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { type ArticlePageFragment } from '#gql';
import { useGlobalContentStore } from '~/store/globalContent';
import WysiwygWrapper from '~/components/WysiwygWrapper.vue';
import ArticleTeaserBlock from '~/components/blocks/ArticleTeaserBlock.vue';

const config = useGlobalContentStore().config;
const globalContentStore = useGlobalContentStore();
const dayjs = useDayjs();

const props = defineProps<{
  page: ArticlePageFragment,
  isMobile: boolean,
  isEditMode: boolean
}>();

const article = computed(() => {
  return props.page as ArticlePageFragment;
});

const blocks = computed(() => {
  return (
    props.page.ContentArea?.filter(
      (block) => block?.ContentLink?.Expanded !== null
    ) || []
  );
});

const logo = computed(() => {
  return new URL('../assets/images/logo-niqo-co-black.svg', import.meta.url).href;
});

const host = globalContentStore.host;
const articleValue = article.value;

const jsonLd = {
  '@context': 'https://schema.org',
  '@type': 'Article',
  date: articleValue.Released,
  headline: articleValue.Heading,
  Image: articleValue?.Image ? host + articleValue?.Image?.Url : null,
  author: {
    '@type': 'Organization',
    name: 'Niqo Co',
  },
  publisher: {
    '@type': 'Organization',
    name: 'Niqo Co',
    logo: {
      '@type': 'ImageObject',
      Url: host + logo.value,
    },
  },
} as any;

useJsonld(jsonLd);
</script>
<style scoped>
.article-desktop-image {
  @screen sm {
    max-height: none;
    aspect-ratio: auto 3 / 1;
  }
}
</style>
