<template>
  <div
    class="text-input"
    :class="{
      'border-b border-white': !formInput,
      'px-16 flex-1': formInput && !newsletterModal,
    }"
  >
    <div :class="{ 'border-b border-neutral-light ': newsletterModal }" class="relative">
      <input
        :id="id"
        ref="inputField"
        :value="input"
        class="input form-control font-normal"
        :class="{
          'border-warning focus:border-warning': !!error,
          'text-grey200': !formInput,
          'text-neutral-dark focus:text-grey800 valid:text-grey800 w-full': formInput
        }"
        required
        :type="showPassword ? 'text': type"
        :maxlength="maxLength"
        :inputmode="inputMode"
        :autocomplete="autocomplete"
        :tabindex="tabindex"
        :autofocus="autofocus"
        @input="(e) => (input = e.target.value)"
        @blur="$emit('blur')"
        @keyup.enter="$emit('enter-submit')"
        @keypress="numberInput($event)"
      >
      <div
        v-if="isPasswordInput"
        class="absolute top-0 right-0 h-full w-24 flex items-center justify-center sm:w-48 sm:h-full -mr-12 cursor-pointer z-20"
        @click.stop="toggleShowPassword"
      >
        <div v-if="showPassword" class="w-16 h-16 pointer-events-none">
          <svg
            class="pointer-events-none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
          ><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm9.4 130.3C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5l-41.9-33zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z" /></svg>
        </div>
        <div v-else class="w-16 h-16 pointer-events-none">
          <svg
            class="pointer-events-none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          > <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>
        </div>
      </div>
      <label
        :for="id"
        class="flex absolute uppercase cursor-text select-none type-xxs text-[12px] meta-info-text w-full pr-16"
        :class="{
          'form-control-placeholder': !formInput,
          'form-input-control-placeholder': formInput,
          'text-grey200': !error && !formInput && !input,
          'text-neutral-dark': !error && formInput && !input && !newsletterModal,
          'text-grey800': !error && !formInput && !input && newsletterModal,
          'text-error': !!error,
          'text-warning': !!warning,
          'has-input text-grey200': !!input && !error && !formInput,
          'has-input text-grey800': !!input && !error && formInput,
          'has-input text-error': !!input && !!error,
        }"
      >
        <fa
          v-if="error"
          class="mr-6 mt-2"
          :icon="['fas', 'exclamation-circle']"
        />
        <fa
          v-else-if="warning"
          class="mr-6 mt-2"
          :icon="['fas', 'exclamation-triangle']"
        />
        <span v-if="warning">
          {{ `${placeholder} - ${warning}` }}
        </span>
        <span v-else class="w-full">
          {{ !!error ? `${placeholder} - ${error}` : placeholder }}
        </span>
      </label>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import debounce from '~/util/debounce.js';
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    warning: {
      type: String,
      required: false,
      default: '',
    },
    formInput: {
      type: Boolean,
      default: true,
    },
    newsletterModal: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
    initValue: {
      type: String,
      required: false,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    delaySubmit: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxLength: {
      type: Number,
      required: false,
      default: 524288,
    },
    autocomplete: {
      type: String,
      required: false,
      default: '',
    },
    tabindex: {
      type: Number,
      required: false,
      default: null,
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    numberOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputMode: {
      type: String,
      required: false,
      default: '',
    },
    isPasswordInput: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      input: '',
      hasFocus: false,
      showPassword: false,
      submitInputDebounced: () => {},
    };
  },
  watch: {
    input() {
      this.submitInputDebounced();
    },
    initValue(newVal) {
      this.input = newVal;
    },
  },
  created() {
    this.submitInputDebounced = debounce(
      this.submitInput,
      this.delaySubmit ? 800 : 300
    );
    this.input = this.initValue;
  },
  methods: {
    submitInput() {
      this.$emit('submitInput', this.input, this.id);
    },
    clear() {
      this.input = '';
    },
    focus() {
      (this.$refs.inputField as HTMLElement)?.focus();
    },
    numberInput(event: any) {
      if (this.numberOnly) {
        const keyCode = event.keyCode ? event.keyCode : event.which;
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 43) {
          event.preventDefault();
        }
      }
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
  },
});
</script>

<style scoped>
.text-input {
  @apply relative;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus, {
    transition: all 5000s ease-in-out 0s;
  }
}

.form-control {
  height: 56px;
  padding-top: 16px;
  padding-bottom: 4px;
  appearance: none;
  border: 0;
  outline: none;
  background: transparent;

  @apply font-400 font-zynSans text-16 md:text-14 w-full;
}

.form-input-control-placeholder.text-error:not(.has-input) {
  @apply top-1/2 transform -translate-y-1/2;
}

.form-control-placeholder,
.form-input-control-placeholder {
  transition: all 200ms;
  top: 22px;
}

.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
  font-size: 10px;
  transform: translate3d(0, -75%, 0);
}

.form-control:focus + .form-input-control-placeholder {
  font-size: 10px;
  transform: translate3d(0, -75%, 0);
  top: 20px;
}

.has-input {
  font-size: 10px;
  transform: translate3d(0, -75%, 0);
  line-height: 14px;
}

</style>
