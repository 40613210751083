<template>
  <div class="border-b border-neutral-light relative py-16">
    <div class="flex">
      <div class="h-64 w-64 sm:h-[72px] sm:w-[72px] flex-shrink-0 rounded flex justify-center items-center bg-grey200 mr-16 relative">
        <div v-if="item.bundleType === 'PickAndMix'">
          <div
            v-for="(product, index) in item.includedItems"
            :key="index"
            class="can w-20 aspect-square object-contain absolute"
          >
            <img
              v-if="product.imageUrl"
              :src="product.imageUrl"
              class="w-full h-full"
              :alt="product.name"
            >
            <div v-else>
              <img
                src="/zynicons/placeholder-can.png"
                class="w-full h-full"
                alt="Can"
              >
            </div>
          </div>
        </div>
        <img
          v-else
          class="max-h-48 max-w-48 object-contain"
          :src="item.imageUrl"
          height="48"
          width="48"
          :alt="item.name"
        >
      </div>
      <div class="flex-1">
        <div class="flex justify-between">
          <div>
            <div class="type-headline-xs thunder-font text-brand-dark mb-2 pr-8">
              {{ item.name }}
            </div>
            <div v-if="item.bundleType === 'PickAndMix'" class="type-xs">
              <div class="cursor-pointer group" @click="expandedBundle = !expandedBundle">
                <span v-if="expandedBundle" class="underline group-hover:no-underline">Dölj innehåll</span>
                <span v-else class="underline group-hover:no-underline">Visa innehåll</span>
                <img
                  src="/zynicons/chevron-down-black.svg"
                  class="w-12 h-12 inline-block ml-6"
                  :class="{
                    'rotate-180': expandedBundle
                  }"
                  alt="Chevron"
                >
              </div>
            </div>
            <div v-else class="type-xs thunder-font">
              {{ item.gtmProperties.variant }}
            </div>
          </div>
          <div
            class="cursor-pointer select-none flex-shrink-0"
            :class="{
              'opacity-25': disabled,
            }"
            @click="deleteItem"
          >
            <img
              src="/zynicons/trash-primary.svg"
              class="h-16 w-16"
              height="16"
              width="16"
            >
          </div>
        </div>
        <div class="flex justify-between items-center mt-8">
          <div class="flex">
            <div
              class="flex min-w-[75px] items-center border border-neutral-light bg-white justify-between type-xxs"
              :class="{
                'opacity-25': disabled,
              }"
            >
              <div
                class="pl-10 pr-12 py-6 cursor-pointer select-none"
                @click="decreaseQuantity"
              >
                <img
                  src="/zynicons/minus.svg"
                  class="w-12 h-12"
                  alt="Minus"
                >
              </div>
              {{ count }}
              <div
                class="pr-10 pl-12 py-6 cursor-pointer select-none small-base-text"
                @click="increaseQuantity"
              >
                <img
                  src="/zynicons/plus.svg"
                  class="w-12 h-12"
                  alt="Plus"
                >
              </div>
            </div>
            <div
              v-if="!!errorMessage"
              class="bg-error rounded small-base-text py-4 px-12 ml-8 text-grey200"
            >
              {{ errorMessage }}
            </div>
          </div>
          <div
            class="type-xs flex-shrink-0 ml-16"
            :class="{
              'text-error': isSample,
              'text-grey800': !item.hasDiscount || !showDiscount,
              'opacity-50': priceLoading,
            }"
          >
            {{ item.price }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="item.bundleType === 'PickAndMix'" class="mt-6">
      <div v-if="expandedBundle" class="mt-16">
        <div
          v-for="product in item.includedItems"
          :key="product.id"
          class="flex items-center relative mb-8"
        >
          <div class="h-32 w-32 flex-shrink-0 rounded flex justify-center items-center mr-12">
            <img
              class="max-h-32 max-w-32 object-contain"
              :src="product.imageUrl"
              height="32"
              width="32"
              :alt="product.name"
            >
          </div>
          <div class="flex justify-between w-full">
            <div class="type-xs block">{{ product.name }}</div>
            <div class="type-xs flex-shrink-0">{{ product.price }}</div>
          </div>
        </div>
        <nuxt-link
          :to="pickAndMixPageUrl + '?lineNo=' + item.lineNo"
          class="btn btn-secondary w-full mt-12"
          @click="closeCartMenu"
        >
          Ändra
        </nuxt-link>
      </div>
    </div>
    <div v-if="item.notices && item.notices.length && isCheckout" class="mt-24">
      <div
        v-for="(notice, index) in item.notices"
        :key="index"
        class="p-16 rounded-md bg-grey500 flex my-16"
      >
        <fa
          :icon="['fal', 'info-circle']"
          class="text-base mr-8 flex-shrink-0"
        />
        <div>
          <p v-if="notice.name" class="small-base-text mb-2">
            {{ notice.name }}
          </p>
          <p
            v-if="notice.description && notice.description.length < 80"
            class="type-xs"
          >
            {{ notice.description }}
          </p>
          <div v-else-if="notice.description">
            <p class="type-xs">
              {{ notice.description.substring(0, 80) + '...' }}
            </p>
            <div
              class="small-base-text underline mt-6 cursor-pointer"
              @click="showPopup(notice)"
            >
              {{ $lang('productListingResources', 'showMorePageText') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="showNoticeFlagPopup && isCheckout"
        class="fixed top-0 left-0 h-full w-full bg-grey900 bg-opacity-50 notice-flag-popup md:flex md:items-center md:justify-center"
        @mousedown.self="closePopup"
      >
        <div
          class="bg-white px-24 py-56 pb-32 relative md:pb-56 md:max-w-428 md:w-10/12"
        >
          <img
            src="~assets/icons/close.png"
            class="absolute right-20 top-20 h-24 w-24 cursor-pointer select-none"
            height="24"
            width="24"
            @click="closePopup"
          >
          <h5 class="preamble-text mb-12 md:text-preamble md:font-semibold">
            {{ noticeFlagTitle }}
          </h5>
          <p class="md:text-base">{{ noticeFlagDescription }}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import _debounce from 'lodash/debounce';
import { type ICartItem, PurchaseType } from '~/models/api-types';
import { usePageContentStore } from '~/store/pageContent';
import { useCartStore } from '~/store/cart';
import * as Sentry from '@sentry/vue';
import { useGlobalContentStore } from '~/store/globalContent';
import { useUiStore } from '~/store/ui';
const config = useGlobalContentStore().config;
const uiStore = useUiStore();
const pageStore = usePageContentStore();
const cartStore = useCartStore();
const pickAndMixPageUrl = config.currentMarket?.pickAndMixPageUrl;

const { apiGet } = useApiFetch();
const { $lang } = useNuxtApp();

const props = defineProps<{
  item: ICartItem,
  purchaseType?: PurchaseType,
  disabled?: boolean,
  showDiscount?: boolean,
}>();

const count = ref(0);
const errorMessage = ref('');
const noticeFlagTitle = ref('');
const noticeFlagDescription = ref('');
const priceLoading = ref(false);
const showNoticeFlagPopup = ref(false);
const expandedBundle = ref();
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let modifyQuantityDebounced = (newCount: number): void => {};

const isCheckout = computed(() => {
  return pageStore.pageType === 'CheckoutPage';
});

const isSample = computed(() => {
  return props.purchaseType === PurchaseType.Sampling;
});

watch(props.item, (newVal) => {
  if (newVal) {
    count.value = props.item?.quantity;
  }
});

onBeforeMount(() => {
  count.value = props.item?.quantity;
  modifyQuantityDebounced = _debounce(modifyQuantity, 300);
});

const decreaseQuantity = () => {
  if (!props.disabled) {
    const newCount = count.value - 1;
    count.value = newCount;
    emit('loadingPrice', true);
    priceLoading.value = true;

    modifyQuantityDebounced(newCount);
  }
};

const increaseQuantity = () => {
  if (!props.disabled) {
    const newCount = count.value + 1;
    count.value = newCount;
    emit('loadingPrice', true);
    priceLoading.value = true;

    modifyQuantityDebounced(newCount);
  }
};

const deleteItem = async() => {
  if (!props.disabled) {
    try {
      emit('loadingPrice', true);
      priceLoading.value = true;
      await cartStore.alterItemQuantity({
        item: {
          ...props.item,
          quantity: 0,
        },
        purchaseType: props.purchaseType || PurchaseType.Standard,
      });
      errorMessage.value = '';
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      emit('loadingPrice', false);
      priceLoading.value = false;
    }
  }
};

const modifyQuantity = async(newCount: number) => {
  try {
    const res: unknown = await cartStore.alterItemQuantity({
      item: {
        ...props.item,
        quantity: newCount,
      },
      purchaseType: props.purchaseType || PurchaseType.Standard,
    });
    const message = (res || '') as string;
    errorMessage.value = message;
    if (message) {
      setTimeout(() => {
        errorMessage.value = '';
        count.value = props.item.quantity;
      }, 2000);
    }
    if (isCheckout.value && typeof (window._sw) != undefined) {
      window._sw(async(api) => {
        api.suspend();
        await updateIngrid();
        api.resume();
      });
    }
  } catch (e) {
    Sentry.captureException(e);
  } finally {
    emit('loadingPrice', false);
    priceLoading.value = false;
  }
};

const emit = defineEmits<{
  (event: 'loadingPrice', value: boolean): void,
}>();

const showPopup = (notice: { name: string; description: string })  => {
  noticeFlagTitle.value = notice.name;
  noticeFlagDescription.value = notice.description;
  showNoticeFlagPopup.value = true;
  document.body.classList.add('locked');
};

const closePopup = () => {
  showNoticeFlagPopup.value = false;
  noticeFlagTitle.value = '';
  noticeFlagDescription.value = '';
  document.body.classList.remove('locked');
};

const updateIngrid = async() => {
  await apiGet(
    `ingrid/update/?purchaseType=${props.purchaseType}&cartChanged=true`
  );
};

const expandBundle = (lineNo: number) => {
  if (expandedBundle.value == lineNo) {
    expandedBundle.value = '';
  } else {
    expandedBundle.value = lineNo;
  }
};

const closeCartMenu = () => {
  uiStore.setShowCartMenu(false);
};
</script>
<style scoped>
.notice-flag-popup {
  backdrop-filter: blur(2px);
  z-index: 9999;
}

.can:nth-child(1) {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-80%);
}

.can:nth-child(2) {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-20%);
}

.can:nth-child(3) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-110%, -15%);
}

.can:nth-child(4) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -15%);
  z-index: 1;
}

.can:nth-child(5) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(10%, -15%);
}
</style>
