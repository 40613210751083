import { defineStore } from 'pinia';
import { useGlobalContentStore } from '~/store/globalContent';
import type { IProductItemsResult } from '~/models/api-types';
const { apiGet, apiPost } = useApiFetch();

export const useFavouritesStore = defineStore('favourites', {
  state: () => ({
    favourites: [] as string[] | null,
    updateFavourites: false as boolean,
    totalItemCount: 0,
  }),
  actions: {
    async fetchFavourites() {
      const config = useGlobalContentStore().config;
      this.updateFavourites = true;

      const query = `user/favourites?&countryCode=${config.currentMarket?.countryCode}`;
      this.favourites = await apiGet<string[]>(query);
      this.buildFavouritesString();

      this.updateFavourites = false;
    },
    async addToFavourites(partNo: string) {
      const config = useGlobalContentStore().config;

      await apiPost<string[]>(
        'user/favourites',
        {
          'countryCode': config.currentMarket?.countryCode,
          'partNo': partNo,
        }
      );
      await this.fetchFavourites();
    },
    async removeFromFavourites(partNo: string) {
      const globalContentStore = useGlobalContentStore();
      const config = useGlobalContentStore().config;
      const runtimeConfig = useRuntimeConfig();
      try {
        await $fetch(`${runtimeConfig.public.apiUrl}user/favourites?`, {
          method: 'DELETE',
          body: {
            'countryCode': config.currentMarket?.countryCode,
            'partNo': partNo,
          },
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': globalContentStore.getAcceptLanguage,
            'RequestVerificationToken': globalContentStore.getTheAntiForgeryToken,
          },
        });
        setTimeout(async() => {
          await this.fetchFavourites();
        }, 200);
      } catch (e: any) {
        console.log(e);
      }
    },
    async fetchProducts(partNoArray: string) {
      const config = useGlobalContentStore().config;

      this.totalItemCount = partNoArray.length;

      const query = `productitems?countryCode=${config.currentMarket?.countryCode}&language=${config?.language}&${partNoArray}`;
      const res = await apiGet<IProductItemsResult>(query);
      if (res) {
        this.totalItemCount = res.items.length;
      }
    },
    async buildFavouritesString() {
      let partNo = '';
      if (this.favourites && this.favourites.length > 0) {
        this.favourites.forEach((item) => {
          partNo += `&PartNumbers=${item}`;
        });
    
        await this.fetchProducts(partNo);
      }
    },
  },
});
