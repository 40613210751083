<template>
  <div
    class="h-24 w-24 animate-all border border-transparent cursor-pointer bookmark tooltip group"
    :class="{
      'absolute right-16 top-12': isProductSpot,
      'relative': !isProductSpot
    }"
    @click="toggleFavourites(partNo)"
  >
    <span
      class="opacity-0 pointer-events-none w-[122px] py-0 bg-black text-white text-center absolute z-10 tooltip-top group-hover:opacity-100 animate-all text-[10px] top-0 left-0 transform -translate-x-[65%] -translate-y-full -mt-8 hidden lg:block"
    >
      <span v-if="!isAddedToFavourites">Lägg till i snabbval</span>
      <span v-else>Ta bort från snabbval</span>
    </span>
    <div class="w-24 h-24 relative">
      <img
        :src="'/zynicons/bookmark.svg'"
        alt="Bookmark"
        height="24"
        width="24"
      >

      <transition name="fade" mode="out-in">
        <img
          v-if="isAddedToFavourites"
          :src="'/zynicons/bookmark-filled.svg'"
          alt="Bookmark"
          class="absolute top-0 left-0"
          height="24"
          width="24"
        >
      </transition>
    </div>
  </div>    
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { useFavouritesStore } from '~/store/favourites';
import { useUserStore } from '~/store/user';
import { useUiStore } from '~/store/ui';

const config = useGlobalContentStore().config;
const globalContentStore = useGlobalContentStore();
const addedFavourites: Ref<string[]> = ref([]);
const favouritesStore = useFavouritesStore();
const userStore = useUserStore();
const uiStore = useUiStore();
const { updateFavourites } = storeToRefs(favouritesStore);

addedFavourites.value = favouritesStore.favourites;

const props = defineProps<{
  partNo: string,
  isProductSpot?: boolean
}>();

const emit = defineEmits<{
  (event: 'remove-favourite', partNo: string): void,
}>();

const isAddedToFavourites = computed(() => {
  return addedFavourites.value && addedFavourites.value.includes(props.partNo);
});

const onOpenUserModal = (partNo: string) => {
  uiStore.setPreventNavigation(true);
  uiStore.setShowLogin(true);
  userStore.setSavedPartNo(partNo);
};

const addToFavourites = (async(partNo: string) => {
  const productPartNo = partNo.toString();
  if (globalContentStore.isLoggedIn && addedFavourites.value) {
    addedFavourites.value.push(productPartNo);
    await favouritesStore.addToFavourites(productPartNo);
  } else {
    onOpenUserModal(productPartNo);
  }
});

const removeFromFavourites = (async(partNo: string) => {
  const index = addedFavourites.value.indexOf(partNo);
  if (index !== -1) {
    addedFavourites.value.splice(index, 1);
  }
  await favouritesStore.removeFromFavourites(partNo);
  emit('remove-favourite', partNo);
});

const toggleFavourites = (async(id: string) => {
  const productPartNo = id.toString();
  if (addedFavourites.value && addedFavourites.value.includes(productPartNo)) {
    removeFromFavourites(productPartNo);
  } else {
    addToFavourites(productPartNo);
  }
});

watch(updateFavourites, async() => {
  addedFavourites.value = favouritesStore.favourites;
});
</script>
