<template>
  <div class="sticky top-0 z-header">
    <div v-if="hasMarketUsps && !hideUsps" class="bg-black">
      <MarketUsps
        :usps="marketUsps"
        :is-mobile="isMobile || false"
        :is-tablet="isTablet || false"
      />
    </div>
    <div v-if="hasSiteMessages && !hideSitemessage">
      <SiteMessage
        v-for="(message, idx) in config.siteMessages"
        :key="idx"
        :site-message="message"
      />
    </div>
    <div
      class="header relative z-20"
      :class="{ 'header--take-over': takeOverHeader && !forceHideTakeOver }"
    >
      <div
        class="md:layout-container md:mx-auto h-full flex justify-between items-center w-full"
      >
        <div class="w-1/4 flex justify-center">
          <nuxt-link
            :to="config.currentMarket?.url || '/'"
            class="text-sm md:type-sm base-text flex w-full pt-2 md:pt-0 items-center pl-12 md:pl-64"
            :class="{
              'text-grey200': takeOverHeader && !forceHideTakeOver,
              'text-black': !(takeOverHeader && !forceHideTakeOver),
            }"
          >
            <ClientOnly><fa :icon="['fal', 'arrow-left']" class="mr-6" /></ClientOnly>
            {{ $lang('sharedResources', 'siteUrlCampaignPage') }}
          </nuxt-link>
        </div>
        <div class="flex w-2/4 justify-center items-center">
          <nuxt-link :to="config.currentMarket?.url || '/'">
            <nuxt-img
              v-if="siteLogo"
              preset="standard"
              loading="lazy"
              :src="siteLogo.url"
              class="max-h-64 object-contain"
            />
            <img
              v-else-if="takeOverHeader && !forceHideTakeOver"
              src="~/assets/images/logo-niqo-co-white.svg"
              class="h-24"
              alt="Niqo Co logo"
            >
            <img
              v-else
              src="~/assets/images/logo-niqo-co-black.svg"
              class="h-24"
              alt="Niqo Co logo"
            >
          </nuxt-link>
        </div>
        <div class="flex justify-end items-center flex-1 w-1/4" />
      </div>
    </div>
    <!-- <TestModeBanner
      v-if="testModeActive"
      @deactivate-test-mode-click="$emit('deactivate-test-mode-click')"
    /> -->
  </div>
</template>
<script setup lang="ts">
import MarketUsps from '~/components/body/MarketUsps.vue';
import SiteMessage from '~/components/body/SiteMessage.vue';
// import TestModeBanner from './TestModeBanner.vue';
import { useGlobalContentStore } from '~/store/globalContent';

const config = useGlobalContentStore().config;

const { $lang } = useNuxtApp();

const props = defineProps<{
  takeOverHeader?: boolean,
  isMobile?: boolean,
  testModeActive?: boolean,
  isTablet: boolean
}>();

const forceHideTakeOver =  ref(false);

const marketUsps = computed(() => {
  const marketUsps = config.currentMarket?.marketUsps || [];
  return marketUsps;
});
const hasMarketUsps = computed(() => {
  return marketUsps.value.length > 0;
});

const hasSiteMessages = computed(() => {
  if (config.siteMessages?.length > 0) return true;
  return false;
});

const hideUsps = computed(() => {
  return config.page?.hideUsps;
});

const hideSitemessage = computed(() => {
  return config.page?.hideAlertBanner;
});

const siteLogo = computed(() => {
  return config.page?.siteLogo;
});

onMounted(() => {
  setupTakeOverObserver();
});

const setupTakeOverObserver = () => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          forceHideTakeOver.value = false;
        } else {
          forceHideTakeOver.value = true;
        }
      });
    },
    { rootMargin: '-100px 0px 0px 0px' }
  );
  setTimeout(() => {
    const element = document.querySelector('.take-over-block-bg');
    if (element) {
      observer?.observe(element as Element);
    }
  }, 500);
};
</script>
<style scoped lang="postcss">
.header {
  @apply h-80 bg-white w-full border-b border-grey500;

  backdrop-filter: blur(30px);
  transition: background 0.3s ease;
  &--take-over {
    @apply bg-transparent border-b-0;

    backdrop-filter: none;
    .header-link {
      @apply text-white;
    }
  }
}
</style>
