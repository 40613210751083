export const Sizes = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  round: 'round',
};

export const Themes = {
  dark: 'dark',
  light: 'light',
  ghost: 'ghost',
  lightGhost: 'light-ghost',
  success: 'success',
  primary: 'primary',
  secondary: 'secondary',
};
