<template>
  <div
    class="search-input flex flex-col"
    :class="{
      'border-b border-white': !formInput,
      'flex-1': formInput,
    }"
  >
    <div class="flex items-center">
      <ClientOnly>
        <fa :icon="['far', 'search']" class="opacity-80 mr-4" />
      </ClientOnly>
      <input
        :id="id"
        ref="searchInput"
        :placeholder="placeholder"
        :value="input"
        class="input form-control text-neutral-dark focus:text-grey80 w-full pt-0 ml-8 mr-4"
        required="required"
        :type="type"
        :maxlength="maxLength"
        :inputmode="inputMode"
        @input="(e) => (input = e.target.value)"
        @blur="$emit('blur')"
        @keyup.enter="$emit('enter-submit')"
      >
      <ClientOnly>
        <fa
          v-if="!isLoading && input.length"
          :icon="['fal', 'times']"
          class="cursor-pointer mr-3"
          @click="clear()"
        />
      </ClientOnly>
      <img
        v-if="isLoading"
        src="~assets/icons/spinner-dark.gif"
        class="h-16 w-16"
        height="16"
        width="16"
      >
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import debounce from '~/util/debounce.js';
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    formInput: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
    initValue: {
      type: String,
      required: false,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    delaySubmit: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxLength: {
      type: Number,
      required: false,
      default: 524288,
    },
    inputMode: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      input: '',
      hasFocus: false,
      isLoading: false,
      submitInputDebounced: () => {},
    };
  },
  watch: {
    input() {
      this.submitInputDebounced();
    },
    initValue(newVal) {
      this.input = newVal;
    },
  },
  created() {
    this.submitInputDebounced = debounce(
      this.submitInput,
      this.delaySubmit ? 800 : 200
    );
    this.input = this.initValue;
  },
  methods: {
    submitInput() {
      this.$emit('submitInput', this.input);
    },
    clear() {
      this.input = '';
      this.focus();
    },
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
    focus() {
      (this.$refs.searchInput as HTMLElement).focus();
    },
  },
});
</script>

<style scoped>
.search-input {
  @apply relative;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    transition: all 5000s ease-in-out 0s;
  }
}

.form-control {
  line-height: 56px;
  height: 56px;
  appearance: none;
  border: 0;
  outline: none;
  background: transparent;

  @apply w-full text-grey800 text-16 sm:text-14;
}

.has-input {
  font-size: 75%;
  transform: translate3d(0, -75%, 0);
}
</style>
