<template>
  <div
    class=" top-0 left-0 bottom-0 max-w-320 w-full
         bg-white flex flex-col justify-start overflow-hidden min-h-screen"
    :class="{
      'fixed': topLevel,
      'absolute': !topLevel,
    }"
  >
    <div
      class="basis-[72px] p-24 shrink-0 flex mb-24"
      :class="{
        'justify-between': !topLevel,
        'justify-end': topLevel
      }"
    >
      <button v-if="!topLevel" @click.stop="$emit('closeChild')">
        <img
          src="/zynicons/chevron-left.svg"
          class="w-24"
          alt="back"
        >
      </button>
      <button @click.stop="$emit('close')">
        <img
          src="/zynicons/close.svg"
          class="w-24"
          alt="close"
        >
      </button>
    </div>

    <div class="overflow-y-auto px-24 pb-80">
      <div v-if="topLevel">
        <div
          v-for="(menuItem, tIndex) in combinedTopLevels"
          :key="tIndex"
          class="flex justify-between items-center type-headline-lg mb-16 h-28"
        >
          <nuxt-link
            v-if="menuItem.href"
            :to="menuItem.href"
            :title="menuItem.text"
            @click="$emit('close')"
          >
            {{ menuItem.text }}
          </nuxt-link>
          <div v-else @click="openSub = menuItem.linkColumns ? menuItem.linkColumns : null">
            {{ menuItem.text }}
          </div>
          <div v-if="menuItem.linkColumns" @click="openSub = menuItem">
            <img src="/zynicons/chevron-right.svg" class="h-24">
          </div>
        </div>

        <div
          v-for="metaLink in metaLinks"
          :key="'metaMenuHeaderItem-' + metaLink.text"
          class="mb-16 cursor-pointer select-none"
          @click="$emit('close')"
        >
          <nuxt-link
            :to="metaLink.href"
            :target="metaLink.target"
            :title="metaLink.title"
            class="type-sm text-neutral-dark"
          >
            {{ metaLink.text }}
          </nuxt-link>
        </div>
      </div>
      <div v-else-if="manual">
        <div
          v-for="(menuGroup, mIndex) in manual.linkColumns"
          :key="mIndex"
        >
          <div v-if="menuGroup.heading" class="type-tag-xxs mb-8 mt-24 uppercase">
            {{ menuGroup.heading }}
          </div>
          <div
            v-for="(menuItem, iIndex) in menuGroup.links"
            :key="iIndex"
            class="flex justify-between items-center"
            :class="{
              'type-headline-sm mb-8 h-20': menuGroup.heading,
              'type-headline-lg mb-16 h-28': topLevel && !menuGroup.heading,
              'type-headline-md mb-12 h-26': !topLevel && !menuGroup.heading,
            }"
          >
            <nuxt-link
              :to="menuItem.href"
              :title="menuItem.text"
              @click="$emit('close')"
            >
              {{ menuItem.text }}
            </nuxt-link>
          </div>
        </div>
        <div v-if="manual.featuredContentItems" class="h-24 sm:h-64" />
        <div v-if="manual.featuredContentItems.length" class="mb-32">
          <template
            v-for="(feature, fIndex) in manual.featuredContentItems"
            :key="fIndex"
          >
            <component
              :is="feature.href ? NuxtLink:'div'"
              :to="feature.href"
              class="block mb-32"
            >
              <NuxtImg
                v-if="feature.imageUrl"
                :src="getImagePrefix(feature.imageUrl)"
                class="mb-16 w-full aspect-16/9 object-cover"
                preset="standard"
                sizes="100vw md:336px"
              />
              <div v-if="feature.heading" class="type-headline-base">{{ feature.heading }}</div>
            </component>
          </template>
        </div>
        <div v-if="manual.latestEvents.length" class="mb-32">
          <template
            v-for="(feature, fIndex) in manual.latestEvents.slice(0,3)"
            :key="fIndex"
          >
            <component
              :is="feature.href ? NuxtLink:'div'"
              :to="feature.href"
              class="block mb-32 relative"
            >
              <div
                v-if="feature.startDate"
                class="absolute left-24 top-24 w-56 text-center"
              >
                <div
                  class="bg-critical-primary py-4
                    capitalize type-headline-xs leading-single text-neutral-lightest
                  "
                >
                  {{ formatDate(feature.startDate, 'm') }}
                </div>
                <div class="bg-neutral-lightest type-headline-md leading-single py-8">
                  {{ formatDate(feature.startDate, 'd') }}
                </div>
              </div>
              <NuxtImg
                v-if="feature.imageUrl"
                :src="getImagePrefix(feature.imageUrl)"
                class="mb-16 w-full aspect-16/9 object-cover"
                preset="standard"
                sizes="100vw md:336px"
              />
              <div v-if="feature.heading" class="type-headline-base">{{ feature.heading }}</div>
              <div v-if="feature.startDate" class="type-xs text-neutral-dark">
                <span>{{ formatDate(feature.startDate, 'full') }}</span>
                <span v-if="feature.endDate">- {{ formatDate(feature.endDate, 'full') }}</span>
              </div>
            </component>
          </template>
        </div>
        <div v-if="manual.latestNews.length" class="mb-32">
          <template
            v-for="(feature, fIndex) in manual.latestNews.slice(0,3)"
            :key="fIndex"
          >
            <component
              :is="feature.href ? NuxtLink:'div'"
              :to="feature.href"
              class="block mb-32"
            >
              <NuxtImg
                v-if="feature.imageUrl"
                :src="getImagePrefix(feature.imageUrl)"
                class="mb-16 w-full aspect-16/9 object-cover"
                preset="standard"
                sizes="100vw md:336px"
              />
              <div v-if="feature.heading" class="type-headline-base">{{ feature.heading }}</div>
            </component>
          </template>
        </div>
      </div>
    </div>
    <transition name="slideInRight">
      <MobileMenu
        v-if="openSub"
        :manual="openSub"
        @close="$emit('close')"
        @close-child="openSub = null"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import type { ILinkItemGroup, IMenuItem } from '~/models/api-types';
const { getImagePrefix } = useImages();
const NuxtLink = resolveComponent('NuxtLink');

interface Props {
  topLevel?: boolean;
  manual?: IMenuItem | null
}

withDefaults(defineProps<Props>(), {
  manual: null,
});

defineEmits<{
  (e: 'close'): void;
  (e: 'closeChild'): void;
}>();

const globalContentStore = useGlobalContentStore();

const metaLinks = globalContentStore.header?.metaLinks || [];

const combinedTopLevels = computed(()=> {
  const mainMenu = globalContentStore.header?.mainMenuContent || [];
  const rightMenu = globalContentStore.header?.rightMenuContent || [];
  return mainMenu.concat(rightMenu);
});

const openSub = ref<ILinkItemGroup[] | null>(null);

const { formatDate } = useDateFormat();

</script>

<style scoped lang="postcss">
</style>
