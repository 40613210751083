<template>
  <div class="layout-container py-40 md:py-56 md:px-56">
    <div
      v-if="block.LinkUrl || block.Heading"
      class="flex justify-between mb-24 md:px-0"
    >
      <h5 v-if="block.Heading" class="type-headline-base md:text-md">
        {{ block.Heading }}
      </h5>
      <nuxt-link
        v-if="block.LinkUrl && block.LinkText"
        :to="block.LinkUrl"
        class="type-base self-end pb-2 md:pb-0"
      >
        {{ block.LinkText }}
      </nuxt-link>
    </div>
    <div class="-mx-24">
      <ClientOnly>
        <Swiper
          v-if="articles && articles.length"
          class="articles-swiper"
          :slider-per-view="'auto'"
          :breakpoints="{
            '1024': {
              simulateTouch: false,
              allowTouchMove: false
            },
          }"
        >
          <SwiperSlide
            v-for="(article, index) in articles"
            :key="index"
            class="swiper-slide relative px-6 sm:px-12 bg-center bg-no-repeat bg-cover"
          >
            <nuxt-link
              v-if="article"
              :to="article.RelativePath"
              class="block w-full group"
            >
              <div
                class="relative mb-20 bg-center bg-no-repeat bg-cover w-full min-h-380 sm:min-h-500"
                :style="backgroundImage(article)"
              />
              <div>
                <div v-if="article.Category?.length" class="mb-8">
                  <div
                    v-for="(category, cIndex) in article.Category"
                    :key="cIndex"
                    class="inline-flex mr-8 mb-8 h-20 px-6 items-center
                        bg-brand-dark text-neutral-lightest
                        type-tag-xxs uppercase"
                  >
                    {{ category?.Name }}
                  </div>
                </div>
                <h5 class="mb-2 group-hover:underline">
                  {{ article.Name }}
                </h5>
                <client-only>
                  <span
                    v-if="article.Released"
                    class="type-sm text-neutral-dark capitalize"
                  >
                    {{ getReleasedDate(article.Released) }}
                  </span>
                </client-only>
              </div>
            </nuxt-link>
          </SwiperSlide>
        </Swiper>
      </ClientOnly>
    </div>
  </div>
</template>
<script setup lang="ts">
import { type ArticleTeaserBlockFragment, type ArticlePageFragment } from '#gql';
import { useGlobalContentStore } from '~/store/globalContent';
const { convertToRelativeUrl } = useStringExtensions();
const config = useGlobalContentStore().config;

const props = defineProps<{
  block: ArticleTeaserBlockFragment,
  isMobile: boolean,
  isEditMode: boolean
}>();

const articles = ref();

const block = computed(() => {
  if (props.block.LinkUrl) {
    props.block.LinkUrl = convertToRelativeUrl(props.block.LinkUrl);
  }
  return props.block as ArticleTeaserBlockFragment;
});

const articleIds = computed(() => {
  const articleIdsArray = [];
  if (block.value?.Articles) {
    for (let i = 0; i < block.value?.Articles.length; i++) {
      articleIdsArray.push(block.value?.Articles[i]?.ContentLink?.GuidValue);
    }
  }
  return articleIdsArray;
});

const backgroundImage = (page: ArticlePageFragment) => {
  if (page.PreviewImage) {
    return `background-image: url(${page.PreviewImage.Url})`;
  }
  return '';
};

const getReleasedDate = (date: string) => {
  const dayjs = useDayjs();
  return dayjs(date).locale(config.language).format('MMMM YYYY');
};

const fetchArticles = async() => {
  const ids = articleIds.value;
  const { data } = await useAsyncGql('Articles', { ids });

  if (data.value) {
    articles.value = data.value?.Content?.items;
  }
};

await fetchArticles();
</script>
<style>
.articles-swiper {
  .swiper {
    @apply pr-32 md:pr-0;
  }
  .swiper-wrapper {
    @apply w-auto -mx-6 sm:-mx-12 px-24;
  }

  .swiper-slide {
    @apply !w-11/12 sm:!w-3/5 md:!w-1/3;
  }
}
</style>
