<template>
  <div class="mobOnly:py-24 border-[#3d6984] border-b md:border-none">
    <div
      class="flex justify-between md:select-text select-none md:cursor-auto cursor-pointer uppercase md:mb-16"
      :class="{
        'text-neutral-dark': colorScheme === 'light',
        'pb-10': isOpen,
      }"
      @click="toggleOpen"
    >
      {{ heading }}
      <div v-if="uiStore.isMobile">
        <ClientOnly>
          <fa v-if="isOpen" :icon="['fal', 'minus']" />
          <fa v-else :icon="['fal', 'plus']" />
        </ClientOnly>
      </div>
    </div>
    <div
      v-if="isOpen || !uiStore.isMobile"
      :class="{
        'flex item-center gap-12': direction === 'left'
      }"
    >
      <div
        v-for="item in items"
        :key="'subMenuFoldOutItem' + item.text"
        class="mobOnly:py-10 md:mb-16 cursor-pointer select-none"
        @click="$emit('close-overlay')"
      >
        <template v-if="item.href.startsWith('http')">
          <a
            :href="item.href"
            :target="item.target"
            :title="item.title"
            class="leading-single"
            :class="{
              'text-neutral-dark': colorScheme === 'light',
              'text-24': item.icon
            }"
          >
            <span v-if="!item.icon">{{ item.text }}</span>
            <ClientOnly v-else>
              <fa
                :icon="['fab', item.icon]"
                :aria-label="item.icon"
              />
            </ClientOnly>
          </a>
        </template>
        <template v-else>
          <nuxt-link
            :to="item.href"
            :target="item.target"
            :title="item.title"
            class="leading-single"
            :class="{
              'text-neutral-dark': colorScheme === 'light',
            }"
          >
            <span>{{ item.text }}</span>
          </nuxt-link>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useUiStore } from '~/store/ui';
import type { ILinkItem } from '~/models/api-types';

export type MenuFoldOutLink = ILinkItem & { icon?: string }

interface Props {
  heading: string,
  items: MenuFoldOutLink[],
  id: string,
  toggledRow: string,
  colorScheme?: string,
  direction?: 'left' | 'down'
}

const props = withDefaults(defineProps<Props>(), {
  colorScheme: 'light',
  direction: 'down',
});

const emit = defineEmits<{
  (e: 'toggleMenuRow', payload: string): void;
  (e: 'close-overlay'): void;
}>();

const uiStore = useUiStore();

const isOpen = computed(()=> {
  return props.toggledRow === props.id;
});

const toggleOpen = () => {
  if (uiStore.isMobile) {
    emit('toggleMenuRow', props.id);
  }
};
</script>
