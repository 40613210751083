<template>
  <div
    class="nicotine-warning sticky z-header md:z-globalWarning top-0
           px-24 h-[100px]
           bg-neutral-lightest
           type-headline-md md:type-headline-md text-center
           flex justify-center items-center
           border-b border-neutral-light !text-black"
  >
    {{ globalContent.currentMarket?.nicotineWarning }}
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
const globalContent = useGlobalContentStore();

</script>

<style scoped lang="postcss">
.nicotine-warning {
  font-family: Arial, Helvetica, Sans-Serif !important;
}
</style>
